.owl {
    position: relative;
    z-index: 1;
    display: none;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
}

.owl .owl-stage {
    position: relative;
    touch-action: pan-y;
}

.owl .owl-stage::after {
    display: block;
    height: 0;
    clear: both;
    line-height: 0;
    visibility: hidden;
    content: ".";
}

.owl .owl-stage-outer {
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
}

.owl .owl-item {
    position: relative;
    float: left;
    min-height: 1px;
    transform: translateZ(0);
    backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}

.owl .owl-item > img {
    display: block;
    transform-style: preserve-3d;
    width: 100%;
}

.owl .owl-nav.disabled,
.owl .owl-dots.disabled {
    display: none;
}

.owl .owl-prev,
.owl .owl-next,
.owl .owl-dot {
    cursor: pointer;
    user-select: none;
}

.owl .owl-prev,
.owl .owl-next {
    position: absolute;
    top: -86px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    padding: 0;
    color: var(--dark-gray-color);
    text-align: center;
    background-color: var(--white-color);
    border: 1px solid var(--light-gray-color);
    border-radius: 3px;
    outline: none;
    transition: color 0.5s var(--animation-base);
}

.owl .owl-prev.disabled,
.owl .owl-next.disabled {
    color: var(--mid-gray-color);
    cursor: default;
}

.owl .owl-prev .icon,
.owl .owl-next .icon {
    width: 18px;
    height: 18px;
}

.owl .owl-prev {
    right: 34px;
}

.owl .owl-next {
    right: 0;
}

.owl .owl-dots {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.owl .owl-dot {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    padding: 0;
    border: 0;
    outline: none;
}

.owl .owl-dot span {
    width: 8px;
    height: 8px;
    background-color: var(--light-gray-color);
    border-radius: 50%;
}

.owl .owl-dot.active span {
    background-color: var(--black-color);
}

.owl.owl-loaded {
    display: block;
}

.owl.owl-loading {
    display: block;
    opacity: 0;
}

.owl.owl-hidden {
    opacity: 0;
}

.owl.owl-refresh .owl-item {
    visibility: hidden;
}

.owl.owl-drag .owl-item {
    user-select: none;
}

.owl.owl-grab {
    cursor: move;
}

.no-js .owl {
    display: block;
}
