.u-permalink {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 50;
    outline: none;
}

.u-placeholder {
    position: relative;
    z-index: 10;
    background-color: var(--lighter-gray-color);
}

.u-placeholder::before {
    display: block;
    content: "";
}

.u-placeholder.horizontal::before {
    padding-bottom: 50%;
}

.u-placeholder.rectangle::before {
    padding-bottom: 62.5%;
}

.u-placeholder.square::before {
    padding-bottom: 100%;
}

.u-object-fit {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
