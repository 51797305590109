@import "@tryghost/shared-theme-assets/assets/css/v1/screen.css";
@import "general/fonts.css";
@import "general/basics.css";
@import "general/button.css";
@import "general/form.css";
@import "general/icons.css";
@import "site/layout.css";
@import "site/header.css";
@import "site/cover.css";
@import "blog/feed.css";
@import "blog/featured.css";
@import "blog/pagination.css";
@import "blog/single.css";
@import "blog/author.css";
@import "blog/share.css";
@import "blog/navigation.css";
@import "blog/related.css";
@import "blog/comment.css";
@import "blog/tag.css";
@import "misc/owl.css";
@import "misc/utils.css";
@import "misc/animations.css";
@import "misc/dark.css";

@import "cards.css";

.toggle-content {
  display: none;
}

.toggle-content.is-visible {
  display: block;
}

.mobile-nav {
  color: var(--primary-text-color);
  background-color: var(--white-color);
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}
