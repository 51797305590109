.related-wrapper {
    padding: 56px 0 32px;
    margin-top: 80px;
    background-color: var(--lighter-gray-color);
}

.related-title {
    margin-bottom: 20px;
    font-size: 2.1rem;
}
