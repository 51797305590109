.gh-head-logo {
    font-size: 2.2rem;
    font-weight: 800;
}

.gh-head-menu,
.gh-head-actions {
    font-size: 1.5rem;
    font-weight: 700;
}

body:not(.is-dropdown-loaded) .gh-head-menu .nav>li {
    opacity: 1;
}
