.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    padding: 0 15px;
    font-size: 11px;
    font-weight: 800;
    color: var(--brand-color);
    text-transform: uppercase;
    letter-spacing: 0.5px;
    cursor: pointer;
    background-color: var(--white-color);
    border: 1px solid var(--light-gray-color);
    border-radius: 3px;
    outline: none;
}

.button:hover {
    border-color: var(--mid-gray-color);
    opacity: 1;
}

.button .icon-loading {
    display: none;
}

.button.loading {
    text-indent: -9999px;
}

.button.loading .icon-loading {
    display: block;
}

.button-secondary {
    color: var(--darker-gray-color);
}

.button-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    padding: 0;
    color: var(--darker-gray-color);
    cursor: pointer;
    background-color: transparent;
    border: 0;
    outline: none;
}

.button-icon:hover .icon {
    opacity: 0.8;
}

.button-text {
    padding: 0;
    margin: 0;
    line-height: inherit;
    color: var(--darker-gray-color);
    cursor: pointer;
    background-color: transparent;
    border: 0;
    outline: none;
}
