label {
    display: block;
    font-size: 1.4rem;
    font-weight: 700;
    color: var(--darker-gray-color);
}

label+label {
    margin-top: 2rem;
}

label input,
label textarea {
    margin-top: 0.7rem;
}

input,
textarea {
    width: 100%;
    height: 44px;
    padding: 0 1.5rem;
    font-size: 1.6rem;
    color: var(--darker-gray-color);
    background-color: var(--white-color);
    border: 1px solid var(--mid-gray-color);
    border-radius: 5px;
    outline: none;
    appearance: none;
}

input:focus,
textarea:focus {
    border-color: var(--mid-gray-color);
}

textarea {
    height: 100%;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    resize: vertical;
}

.form-wrapper {
    position: relative;
}

.form-button {
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    color: var(--white-color);
    cursor: pointer;
    background-color: var(--brand-color);
    border: 0;
    border-radius: 0 4px 4px 0;
    outline: none;
}

.form-button .icon-loading {
    display: none;
}

.loading .form-button .icon {
    display: none;
}

.loading .form-button .icon-loading {
    display: block;
}

.contact-form {
    margin-top: 3rem;
}

.contact-form .button {
    margin-top: 1.5rem;
}
