.featured-wrapper {
    margin-top: 60px;
}

.featured-wrapper .u-placeholder {
    margin-bottom: 24px;
}

.featured-wrapper .post-title {
    margin-bottom: 0;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.3;
    letter-spacing: -0.01em;
    word-break: break-word;
}

.has-serif-title .featured-wrapper .post-title {
    font-family: var(--font-serif);
}

.featured-title {
    padding-bottom: 1rem;
    margin-bottom: 4.5rem;
    font-size: 1.5rem;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 0.01em;
    border-bottom: 1px solid var(--light-gray-color);
}
